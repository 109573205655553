import React, { useEffect, useState, useCallback } from 'react';
import 'firebase/database';
import firebase from './firebaseConfig';
import 'firebase/compat/database';
import 'firebase/compat/auth';
import 'firebase/auth';
import BloomLogo from '../src/assets/images/Bloom_logo_Darkmode.svg'
import { useNavigate, useLocation } from "react-router-dom";
import { HiMiniArrowRightOnRectangle } from "react-icons/hi2";
import { RiUserHeartLine } from "react-icons/ri";

const Chat = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState(null);
  const [chatData, setChatData] = useState([]);
  const [loading, setIsLoading] = useState(false);

  const loadChatData = useCallback(async () => {
    setIsLoading(true);
    const supportMessagesRef = firebase.database().ref('support');
    const unsubscribe = supportMessagesRef.on('value', (supportSnapshot) => {
      const supportData = supportSnapshot.val();
      if (!supportData) {
        setChatData([]);
        setIsLoading(false);
        return;
      }
      let latestMessages = {};
      for (const userId in supportData) {
        const userInfo = supportData[userId];
        const receivedMessages = supportData[userId]['received'] || {};
        const sentMessages = supportData[userId]['coach'] || {};
        const allMessages = { ...receivedMessages, ...sentMessages };
        for (const messageId in allMessages) {
          const message = allMessages[messageId];
          if (userInfo.status === 'active' && (!latestMessages[userId] || new Date(message.createdAt) > new Date(latestMessages[userId].createdAt))) {
            latestMessages[userId] = {
              userId,
              user: userId,
              displayName: userInfo.name,
              lastMessage: message.text,
              status: message.status,
              queue: userInfo.status,
              createdAt: message.createdAt,
              formattedTime: formatDate(message.createdAt),
            };
          }
        }
      }
      if (Object.keys(latestMessages).length === 0) {
        setChatData([]);
      } else {
        const loadedChats = Object.values(latestMessages);
        loadedChats.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setChatData(loadedChats);
      }
      setIsLoading(false);
    });
    return () => {
      supportMessagesRef.off('value', unsubscribe);
    };
  }, []);

  useEffect(() => {
    const userCheck = firebase.auth().onAuthStateChanged((firebaseUser) => {
      setUser(firebaseUser);
      if (firebaseUser) {
        loadChatData();
        return location.pathname;
      } else {
        navigate(`/`);
      }
    });
    return () => userCheck();
  }, [navigate, loadChatData, location.pathname]);

  const formatDate = (createdAt) => {
    const messageDate = new Date(createdAt);
    const currentDate = new Date();
    const startOfDayCurrent = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    const startOfDayMessage = new Date(messageDate.getFullYear(), messageDate.getMonth(), messageDate.getDate());
    const differenceInTime = startOfDayCurrent - startOfDayMessage;
    const differenceInDays = Math.floor(differenceInTime / (1000 * 60 * 60 * 24));

    if (differenceInDays < 1) {
      return messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    } else if (differenceInDays === 1) {
      return 'Yesterday';
    } else if (differenceInDays < 7) {
      return messageDate.toLocaleDateString('en-US', { weekday: 'long' });
    } else {
      return messageDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' });
    }
  };

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  };

  const handleBack = async () => {
    await firebase.auth().signOut();
    navigate(`/`);
  };

  const handleChatClick = async (user) => {
    let foundUserId = null;
    Object.keys(chatData).forEach(userId => {
      const data = chatData[userId];
      if (data.userId === user) {
        foundUserId = data.userId;
      }
    });
    if (foundUserId) {
      const receivedRef = firebase.database().ref(`support/${foundUserId}/received`);
      const receivedSnap = await receivedRef.once('value');
      const receivedData = receivedSnap.val();

      if (receivedData) {
        Object.keys(receivedData).forEach(messageId => {
          const message = receivedData[messageId];

          if (message.status === "unread") {
            receivedRef.child(messageId).update({ status: 'read' })
          }
        });
      }
      navigate(`/message/${user}`, { state: { userId: foundUserId } });
    }
  }

  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <img src={BloomLogo} width={100} height={100} alt="Bloom Logo" />
      </header>
      <section>
        <div style={styles.headerContent}>
          <div style={styles.userInfo}>
            <div style={styles.avatar}>
              <RiUserHeartLine style={styles.nurseIcon} />
            </div>
            <div style={styles.userDetails}>
              <p style={styles.userName}>{user ? user.displayName : 'Loading user...'}</p>
              <p style={styles.coachText}>Fertility coach</p>
            </div>
          </div>
          <HiMiniArrowRightOnRectangle onClick={handleBack} style={styles.logoutIcon} />
        </div>
      </section>
      {loading ? (
        <div style={styles.noMessagesContainer}>
          <span>Loading...</span>
        </div>
      ) : chatData.length === 0 ? (
        <div style={styles.noMessagesContainer}>
          <span>No new messages</span>
        </div>
      ) : (
        <div style={styles.chatListContainer}>
          {chatData.map(chat => (
            <div key={chat.userId} style={styles.chatItem} onClick={() => handleChatClick(chat.user)}>
              <div style={styles.avatar}>
                {chat.displayName ? chat.displayName[0] : '?'}
              </div>
              <div style={styles.chatInfo}>
                <div style={styles.chatNameContainer}>
                  {chat.status === 'unread' && <span style={styles.unreadIndicator}></span>}
                  {chat.displayName || 'Unknown User'}
                </div>
                <div style={{
                  ...styles.chatPreview,
                  opacity: chat.status === 'unread' ? 1 : 0.5
                }}>
                  {truncateText(chat.lastMessage, 30)}
                </div>
              </div>
              <div style={styles.messageTimeContainer}>
                <span>{chat.formattedTime}</span>
              </div>
            </div>
          ))}
        </div>
      )}
      <hr style={styles.hrStyle} />
    </div>
  );
};

const styles = {
  container: {
    textAlign: 'center',
    backgroundColor: '#20253D',
    margin: '0 auto',
    maxWidth: 800,
    minHeight: '100vh',
  },
  headerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#313858',
    width: '100%',
  },
  logoutIcon: {
    color: 'white',
    width: 25,
    height: 25,
    paddingRight: 20,
    paddingTop: 5,
  },
  user: {
    margin: '20px 0',
    color: '#FFFFFF',
    textAlign: 'left',
    justifyContent: 'relative',
    fontFamily: 'CircularStd-Bold',
    fontSize: 18,
    paddingLeft: 20,
  },
  userInfo: {
    display: 'flex',
    alignItems: 'center',
    padding: 10,
  },
  userDetails: {
    textAlign: 'left',
    marginLeft: 10,
  },
  userName: {
    color: '#FFFFFF',
    fontFamily: 'CircularStd-Bold',
    fontSize: 18,
    marginBottom: -15,
  },
  coachText: {
    color: '#FFFFFF',
    fontFamily: 'CircularStd-Book',
    fontSize: 18,
  },
  avatar: {
    width: 40,
    height: 40,
    backgroundColor: '#A7C2ED',
    borderRadius: '50%',
    color: 'black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
  },
  nurseIcon: {
    color: 'black',
    fontSize: 20,
  },
  chatListContainer: {
    flex: 1,
    maxHeight: 'calc(100vh - 250px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  chatItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
    paddingTop: 30,
    cursor: 'pointer',
    width: '100%',
  },
  chatInfo: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    marginRight: 10,
  },
  chatNameContainer: {
    fontWeight: 'bold',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 12,
  },
  noMessagesContainer: {
    color: 'white',
    fontSize: 20,
    fontFamily: 'CircularStd-Bold',
    padding: 20,
  },
  chatPreview: {
    margin: 0,
    color: 'white',
    paddingTop: 5,
    paddingLeft: 12,
    textAlign: 'left',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '300px',
  },
  unreadIndicator: {
    width: 10,
    height: 10,
    backgroundColor: '#7AAD67',
    borderRadius: '50%',
    display: 'inline-block',
    marginLeft: -18,
    marginRight: 10,
  },
  hrStyle: {
    borderColor: 'rgba(167, 194, 237, 0.5)',
    marginLeft: '5%',
    marginRight: '5%',
  },
  messageTimeContainer: {
    display: 'flex',
    fontSize: 12,
    color: '#999999',
    paddingRight: '5%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexShrink: 0,
  },
};

export default Chat;
