import { useEffect } from 'react';
import 'firebase/database';
import firebase from '../firebaseConfig';
import 'firebase/compat/database';
import 'firebase/compat/auth';
import 'firebase/auth';
import { useNavigate, useLocation } from "react-router-dom";

function Timer() {
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		if (location.pathname === '/') {
			return;
		}

		let timeInterval = setInterval(async () => {
			let lastActivity = localStorage.getItem('lastActivity');
			if (!lastActivity) {
				return;
			}

			var diffMs = Math.abs(new Date(lastActivity) - new Date())
			var seconds = Math.floor((diffMs / 1000));
			var minutes = Math.floor((seconds / 60));

			if (minutes >= 30) {
				clearInterval(timeInterval);
				await firebase.auth().signOut();
				navigate('/');
			}
		}, 1000);

		return () => clearInterval(timeInterval);
	}, [navigate, location.pathname]);
};


export default Timer;