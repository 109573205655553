/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useLocation, useNavigate } from 'react-router-dom';
import 'firebase/database';
import firebase from './firebaseConfig';
import 'firebase/compat/database';
import 'firebase/compat/auth';
import 'firebase/auth';
import BloomLogo from '../src/assets/images/Bloom_logo_Darkmode.svg'
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { FaArrowLeft } from "react-icons/fa";
import { IoPaperPlaneOutline } from "react-icons/io5";
import { RiUserHeartLine } from "react-icons/ri";
import { FiMinusCircle } from "react-icons/fi";

const Message = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [messages, setMessages] = useState([]);
	const [chatName, setChatName] = useState(null);
	const [chatData, setChatData] = useState([]);
	const { userId } = location.state || {};
	const [newMessage, setNewMessage] = useState('');
	const messagesEndRef = useRef(null);
	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		scrollToBottom();
	}, [messages]);

	useEffect(() => {
		const fetchMessages = async () => {
			if (userId) {
				const userRef = firebase.database().ref(`support/${userId}`);
				const unsubscribe = userRef.on('value', async (userSnapshot) => {
					let mergedMessages = {};
					let sortedMessages = {};
					if (userId) {
						const infoRef = firebase.database().ref(`support/${userId}`);
						const receivedRef = firebase.database().ref(`support/${userId}/received`);
						const coachRef = firebase.database().ref(`support/${userId}/coach`);
						const infoSnap = await infoRef.once('value');
						const receivedSnap = await receivedRef.once('value');
						const coachSnap = await coachRef.once('value');
						const receivedData = receivedSnap.val();
						const coachData = coachSnap.val();
						const name = infoSnap.child('name').val();
						setChatName(name);
						receivedRef.once('value', (snapshot) => {
							const messages = snapshot.val();
							for (const messageId in messages) {
								const message = messages[messageId];
								const isCurrentSession = window.location.pathname.includes(userId);
								if (message.status === 'unread' && isCurrentSession && !window.location.pathname.includes('home')) {
									receivedRef.child(messageId).update({ status: 'read' });
								}
							}
						});

						if (coachData) {
							mergedMessages = [...userMessages(coachData, true), ...userMessages(receivedData, false)];
						} else {
							mergedMessages = [...userMessages(receivedData, false)];
						}
						sortedMessages = mergedMessages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
						setChatData(sortedMessages);
					}
					setChatData(sortedMessages);
				});

				return () => {
					userRef.off('value', unsubscribe);
				};
			}
		}
		fetchMessages();
	}, [location.pathname, userId]);

	useEffect(() => {
		if (userId) {
			const receivedRef = firebase.database().ref(`support/${userId}/received`);
			const coachRef = firebase.database().ref(`support/${userId}/coach`);
			const onNewMessage = () => {
				setMessages(prevMessages => [
					...prevMessages,
				]);
			};
			receivedRef.on('child_added', snapshot => onNewMessage(snapshot, false));
			coachRef.on('child_added', snapshot => onNewMessage(snapshot, true));

			return () => {
				receivedRef.off('child_added');
				coachRef.off('child_added');
			};
		}
	}, [newMessage, userId]);

	const userMessages = (messages, isCoach) => {
		if (!messages) {
			navigate('/home');
			return [];
		}

		return Object.values(messages).map(msg => ({
			_id: msg._id,
			text: msg.text,
			createdAt: new Date(msg.createdAt),
			user: {
				_id: isCoach ? 1 : 2,
			},
		})).filter(msg => msg.createdAt !== 'Invalid Date' && msg.text);
	};

	function handleSendKeyPress(event) {
		if (event.key === 'Enter') {
			event.preventDefault();
			onSend();
		}
	};

	const onSend = async () => {
		if (!userId || !newMessage.trim()) {
			return;
		}

		let newMessageObject = {
			_id: uuidv4(),
			text: newMessage,
			createdAt: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
			user: { _id: 1 },
		};

		const userMessageRef = firebase.database().ref(`users/${userId}/messages/received`);
		const coachMessageRef = firebase.database().ref(`support/${userId}/coach`);
		userMessageRef.push({ ...newMessageObject, user: { _id: 1 } });
		coachMessageRef.push({ ...newMessageObject, user: { _id: 2 } });
		setNewMessage('');
	};

	const handleBack = () => {
		navigate('/home', { state: { from: 'Message' } });
	};

	const handleCloseConversation = () => {
		setShowModal(true);
	};

	const handleClose = () => {
		setShowModal(false);
	};

	const handleConfirm = async () => {
		setShowModal(false);

		try {
			if (!userId) {
				console.error('User ID is null or undefined.');
				return;
			}
			await firebase.database().ref(`support/${userId}`).update({status: 'inactive'});
			checkStatus();
		} catch (error) {
			console.error('Error: ', error);
		}
	};

	const checkStatus = async () => {
		const userRef = firebase.database().ref(`support/${userId}`);
			const userSnap = await userRef.once('value');
			const status = userSnap.child('status').val();
			if (status === 'inactive') {
				navigate('/home');
				return [];
			}
	};

	const scrollToBottom = () => {
		if (messagesEndRef.current) {
			messagesEndRef.current.scrollIntoView({ behavior: 'auto' });
		}
	};

	const renderDate = (index, data) => {
		if (index === 0) return true;
		const currentDate = moment(data[index].createdAt).format('LL');
		const previousDate = moment(data[index - 1].createdAt).format('LL');

		return currentDate !== previousDate;
	};

	const ConfirmationModal = ({ show, handleClose, handleConfirm }) => {
		if (!show) return null;
		return (
			<Modal style={styles.modal} show={show} size="sm" onHide={handleClose}>
				<Modal.Header style={styles.modalHeader}>
					<Modal.Title>Are you sure you want to close this conversation?</Modal.Title>
				</Modal.Header>
				<Modal.Body style={styles.modalBody}>All messages will be archived and removed from the queue.</Modal.Body>
				<Modal.Footer style={styles.modalFooter}>
					<Button style={styles.noButton} variant="secondary" onClick={handleClose}>
						No
					</Button>
					<Button style={styles.yesButton} variant="primary" onClick={handleConfirm}>
						Yes
					</Button>
				</Modal.Footer>
			</Modal>
		);
	};

	return (
		<div style={styles.container}>
			<ConfirmationModal
				show={showModal}
				handleClose={handleClose}
				handleConfirm={handleConfirm}
			/>
			<header style={styles.header}>
				<img src={BloomLogo} width={100} height={100} alt="Bloom Logo" />
			</header>
			<section>
				<div style={styles.headerContent}>
					<FaArrowLeft style={styles.backIcon} onClick={handleBack} />
					<div style={styles.userInfo}>
						<div style={styles.userDetails}>
							<p style={styles.userName}>{userId ? chatName : 'Loading user...'}</p>
						</div>
					</div>
					<div>
						<FiMinusCircle onClick={handleCloseConversation} style={styles.endConvoIcon} />
					</div>
				</div>
			</section>
			<section style={styles.messagesSection}>
				<div>
					{Array.isArray(chatData) && chatData.map((msg, index) => (
						<React.Fragment key={index}>
							<div>
								{chatData && chatData.length > 0 && renderDate(index, chatData) && <div style={styles.dateText}>{moment(msg.createdAt).format('LL')}</div>}
							</div>
							<div style={{ display: 'flex', alignItems: 'flex-end', paddingBottom: 20, flexDirection: msg.user._id === 1 ? 'row-reverse' : 'row' }}>
								<div style={msg.user._id === 1 ? styles.coachAvatar : styles.receivedAvatar}>
									{msg.user._id === 1 ? <RiUserHeartLine style={styles.nurseIcon} /> : (chatName[0] || null)}
								</div>
								<div style={{ ...styles.messageBubble, ...(msg.user._id === 1 ? styles.coachMessage : styles.receivedMessage) }}>
									<span style={styles.messageText}>{msg.text}</span>
									<span style={styles.messageTime}>{moment(msg.createdAt).format('LT')}</span>
								</div>
							</div>
						</React.Fragment>
					))}
				</div>
				<div ref={messagesEndRef} />
			</section>
			<div style={styles.inputContainer}>
				<textarea
					style={styles.input}
					value={newMessage}
					onChange={(e) => setNewMessage(e.target.value)}
					placeholder="Reply..."
					rows={2}
					wrap="soft"
					autoComplete="on"
					onKeyDown={handleSendKeyPress}
				/>
				<button style={styles.sendButton} onClick={onSend}><IoPaperPlaneOutline /></button>
			</div>
		</div>
	);
};

const styles = {
	container: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		backgroundColor: '#20253D',
		maxWidth: 800,
		height: '100vh',
		margin: '0 auto',
		textAlign: 'center',
	},
	modal: {
		backgroundColor: 'white',
		paddingTop: 10,
		display: 'flex',
		color: '#313858',
		alignItems: 'center',
		justifyContent: 'center',
		minHeight: 'calc(10% - 60px)',
		marginTop: 'auto',
		marginBottom: 'auto',
	},
	modalHeader: {
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: 18,
	},
	modalBody: {
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		fontSize: 16,
	},
	modalFooter: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		paddingTop: 5,
		paddingLeft: 5,
		paddingRight: 5,
	},
	noButton: {
		display: 'block',
		width: '100%',
		height: 30,
		marginBottom: 10,
		backgroundColor: '#EFA2B1',
		border: 'none',
		outline: 'none',
		marginRight: 10,
		fontSize: 16,
		fontWeight: 'bold',
	},
	yesButton: {
		display: 'block',
		width: '100%',
		height: 30,
		marginBottom: 10,
		backgroundColor: '#7AAD67',
		border: 'none',
		outline: 'none',
		fontSize: 16,
		fontWeight: 'bold',
	},
	headerContent: {
		display: 'flex',
		backgroundColor: '#313858',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		marginBottom: 20,
	},
	nurseIcon: {
		color: 'black',
		fontSize: 20,
	},
	backIcon: {
		color: 'white',
		fontSize: 20,
		cursor: 'pointer',
		paddingLeft: '5%',
	},
	userName: {
		color: '#FFFFFF',
		fontFamily: 'CircularStd-Bold',
		fontSize: 18,
	},
	userInfo: {
		display: 'flex',
		alignItems: 'center',
		padding: 10,
		fontFamily: 'system-ui',
	},
	userDetails: {
		textAlign: 'left',
		marginLeft: 10,
	},
	endConvoIcon: {
		color: '#EFA2B1',
		fontSize: 25,
		paddingRight: 20,
		paddingTop: 5,
	},
	user: {
		margin: '20px 0',
		color: '#FFFFFF',
		textAlign: 'left',
		justifyContent: 'relative',
		fontFamily: 'CircularStd-Bold',
		fontSize: 18,
		paddingLeft: 20,
	},
	dateText: {
		color: 'white',
		fontSize: 14,
		paddingBottom: 20,
	},

	receivedAvatar: {
		width: 40,
		height: 40,
		backgroundColor: '#A7C2ED',
		borderRadius: '50%',
		color: 'black',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginRight: 10,
		marginLeft: 10,
	},
	coachAvatar: {
		width: 36,
		height: 36,
		backgroundColor: '#A7C2ED',
		borderRadius: 18,
		color: 'black',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginRight: 10,
		marginLeft: 10,
	},
	messageBubble: {
		maxWidth: 'calc(80% - 50px)',
		padding: 10,
		borderRadius: 10,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		paddingBottom: 5,
	},
	messagesSection: {
		flex: 1,
		overflowY: 'auto',
	},
	receivedMessage: {
		backgroundColor: 'white',
		color: 'black',
		textAlign: 'left',
	},
	coachMessage: {
		backgroundColor: '#A7C2ED',
		color: 'black',
		textAlign: 'left',
		marginLeft: 'auto',
	},
	messageText: {
		fontSize: 14,
	},
	messageTime: {
		fontSize: 10,
		display: 'block',
		marginTop: 5,
		color: '#20253D',
	},
	inputContainer: {
		display: 'flex',
		backgroundColor: '#20253D',
		borderColor: 'transparent',
		paddingVertical: 10,
		paddingHorizontal: 15,
		position: 'sticky',
		paddingLeft: '3%',
		paddingRight: '3%',
	},
	input: {
		backgroundColor: 'transparent',
		color: 'white',
		borderColor: 'white',
		paddingLeft: 10,
		borderTopWidth: 2,
		borderWidth: 2,
		borderTopColor: 'white',
		borderRadius: 15,
		marginBottom: 20,
		fontSize: 14,
		paddingRight: 45,
		boxSizing: 'border-box',
		paddingTop: 20,
		resize: 'none',
		overflow: 'auto',
		width: '100%',
		height: 'auto',
		minHeight: 60,
		lineHeight: '20px',
		outline: 'none',
		fontFamily: 'system-ui',
	},
	sendButton: {
		padding: '10px 20px',
		border: 'none',
		backgroundColor: 'transparent',
		color: 'white',
		fontSize: 20,
		marginLeft: -60,
		marginTop: -15,
		cursor: 'pointer',
		outline: 'none',
	},
};

export default Message;