import React, { useEffect } from 'react';
import Chat from './Chat';
import Login from './Login';
import Message from './Message';
import Timer from './components/Timer';
import fontLoader from './fonts';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

function App() {
  useEffect(() => {
    fontLoader();
    const updateLastActivity = () => {
      localStorage.setItem('lastActivity', new Date().toISOString());
    };

    window.addEventListener('mousemove', updateLastActivity);
    window.addEventListener('click', updateLastActivity);
    window.addEventListener('keydown', updateLastActivity);

    return () => {
      window.removeEventListener('mousemove', updateLastActivity);
      window.removeEventListener('click', updateLastActivity);
      window.removeEventListener('keydown', updateLastActivity);
    };
  }, []);

  return (
    <div style={{ backgroundColor: '#20253D', minHeight: '100vh' }}>
      <Router>
      <Timer />
        <Routes>
          <Route
            exact
            path="/"
            element={<Login />}
          />
          <Route
            path="/home"
            element={<Chat />}
          />
          <Route
            path="/message/:num"
            element={<Message />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
