import React, { useState } from 'react';
import 'firebase/database';
import firebase from './firebaseConfig';
import 'firebase/compat/database';
import 'firebase/compat/auth';
import BloomLogo from '../src/assets/images/Bloom_logo_Darkmode.svg'
import { useNavigate } from "react-router-dom";

const Login = () => {
	const navigate = useNavigate();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [errorMessage, setErrorMessage] = useState('');

	const handleLogin = async () => {
		if (email.trim() === '' || password.trim() === '') {
			setErrorMessage('Please enter your email address and password');
			return;
		}
		try {
			const userCredentials = await firebase.auth().signInWithEmailAndPassword(email, password);
			const user = userCredentials.user;
			const userId = user.uid;
			const userRoleRef = firebase.database().ref(`users/${userId}/userRole/role`);

			userRoleRef.once('value', (snapshot) => {
				if (snapshot.exists() && snapshot.val() === 'coach') {
					navigate('/home');
				} else {
					setErrorMessage('You may not be permitted to log in. Please make sure you are using the correct email.');
				}
			});
		} catch (error) {
			handleLoginError(error);
		}
	};

	function handleLoginKeyPress(event) {
		if (event.key === 'Enter') {
			handleLogin();
		}
	}


	const handleLoginError = (error) => {
		switch (error.code) {
			case 'auth/user-not-found':
				setErrorMessage('There is no registered account with this email.');
				break;
			case 'auth/wrong-password':
				setErrorMessage('The email or password may be incorrect.');
				break;
			default:
				setErrorMessage('An error occurred while logging in.');
				console.error('error handling login: ', error);
				break;
		}
	};

	return (
		<div style={styles.loginPage}>
			<div style={styles.logoContainer}>
				<img src={BloomLogo} alt="Bloom Logo" />
			</div>
			<div style={styles.coachText}>Fertility coach login</div>
			<div style={styles.loginContainer} onKeyDown={handleLoginKeyPress}>
				<input
					style={styles.input}
					type="email"
					value={email}
					onChange={(e) => {
						setEmail(e.target.value);
						setErrorMessage('');
					}}
					placeholder="Email"
				/>
				<input
					style={styles.input}
					type="password"
					value={password}
					onChange={(e) => {
						setPassword(e.target.value);
						setErrorMessage('');
					}}
					placeholder="Password"
				/>
				<button style={styles.loginButton} onClick={handleLogin}>Login</button>
			</div>
			{errorMessage && <p style={styles.errorText}>{errorMessage}</p>}
		</div>
	);
};

const styles = {
	loginPage: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		alignItems: 'center',
		minHeight: '100vh',
		padding: 20,
		boxSizing: 'border-box',
		backgroundColor: '#20253D',
	},
	logoContainer: {
		justifyContent: 'center',
		alignItems: 'center',
	},
	loginContainer: {
		flexDirection: 'column',
		alignItems: 'center',
		display: 'flex',
		maxWidth: 300,
		width: '100%',
	},
	coachText: {
		color: '#FFFFFF',
		fontSize: 28,
		paddingTop: 40,
		paddingBottom: 70,
		fontFamily: 'CircularStd-Book',
	},
	image: {
		maxWidth: '90%',
		maxHeight: '90%',
	},
	buttonContainer: {
		paddingTop: 50,
	},
	primaryButtonText: {
		color: '#20253D',
		fontSize: 24,
	},
	input: {
		position: 'relative',
		backgroundColor: 'white',
		borderRadius: 50,
		borderWidth: 3,
		borderColor: 'white',
		alignItems: 'center',
		width: 300,
		padding: 15,
		marginBottom: 40,
		outline: 'none',
		border: 0,
		fontSize: 16,
		fontFamily: 'CircularStd-Bold',
	},
	loginButton: {
		position: 'relative',
		backgroundColor: '#A7C2ED',
		borderRadius: 50,
		borderWidth: 3,
		borderColor: '#A7C2ED',
		alignItems: 'center',
		alignSelf: 'center',
		width: 335,
		padding: 15,
		fontSize: 24,
		fontFamily: 'CircularStd-Bold',
		outline: 'none',
		border: 0,
		color: 'black',
	},
	errorText: {
		paddingTop: 5,
		marginBottom: 20,
		color: '#ff6c6c',
		display: 'flex',
	},
}

export default Login;
