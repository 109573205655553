import bookFont from './assets/fonts/CircularXX-Book.otf';
import boldFont from './assets/fonts/CircularXX-Bold.otf';

const fonts = () => {
  const fontFaceStyle = `
    @font-face {
      font-family: 'CircularStd-Book';
      src: url(${bookFont}) format('opentype');
      font-weight: normal;
      font-style: normal;
    }
    @font-face {
      font-family: 'CircularStd-Bold';
      src: url(${boldFont}) format('opentype');
      font-weight: bold;
      font-style: normal;
    }`;

  const styleTag = document.createElement('style');
  styleTag.innerHTML = fontFaceStyle;
  document.head.appendChild(styleTag);
};

export default fonts;
