import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBzsi2BobjHMLGqa4mqiupWLwim82W9Q88",
  authDomain: "bloom-34de5.firebaseapp.com",
  databaseURL: "https://bloom-34de5-default-rtdb.firebaseio.com",
  projectId: "bloom-34de5",
  storageBucket: "bloom-34de5.appspot.com",
  messagingSenderId: "71365445100",
  appId: "1:71365445100:web:58e937e6bee121a9c9acc9",
  measurementId: "G-VS1WETBNJC"
};

firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();

auth.onAuthStateChanged((user) => {
  if (user) {
    console.log("------User is signed in");
  } else {
    console.log("------User is signed out");
  }
})

export default firebase;